import React from "react"

function RedirectURL(props) {
  const { pageContext } = props
  const { pageContent } = pageContext

  const renderRedirect = (RedirectURL) => {

    //isolate the end
    let pathParams = (typeof window !== 'undefined' ? window.location.href.replace(window.location.origin, '').replace(window.location.pathname, '') : "")
    //determine if end slash is used
    let isSlash = (typeof window !== 'undefined' ?  (window.location.pathname[window.location.pathname.length - 1] === '/' ? '/' : '') : "")
    
    //construct path
    let finalPath = RedirectURL + (RedirectURL.includes('?') ? pathParams.split('?').join('&') : isSlash + pathParams)
        finalPath = finalPath.replace('//', '/');
    //navigate
    (typeof window !== 'undefined' ?  window.location = finalPath : console.log("Redirect Failed to " + RedirectURL))
    
    }

  return (
    <>
    {renderRedirect(pageContent.RedirectURL.url)}
    </>
  )

}
export default RedirectURL